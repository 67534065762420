import React from "react";
import { Container, Button } from "react-bootstrap";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const PopUp = styled(Container)`  
  h1 {
    color: white;
    font-size: large;
    margin-bottom: .25em;
    text-align: center;
  }

  .header {
    background-color: #338FDC;
    margin: .5em;
    padding: .5em;
  }

  .event-title {
    font-size: 1.2em;
    color: white;
    text-align: center;
  }

  .address {
    margin-bottom: .5em;
  }

  .date {
    text-align: center;
    margin-bottom: 1em;
  }

  h5 {
    font-weight: bold;
    padding-bottom: .25em;
  }

  .profile-button {
    text-align: center;
    margin-top: .5em;
  }

`;

export default function MapPopUp({ info }) {
  var startDate = new Date(info.events[0].timestamp);
  var endDate = new Date(info.events[0].timestamp_end);

  var startTime = startDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true})
  var endTime = endDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true})

  function replaceURLs(message) {
    if(!message) return;

    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?:')) {
        hyperlink = 'http://' + hyperlink;
      }
      var str = '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
      return str;
    });
  }
  var parser = new DOMParser();
  var normalizedDescription = replaceURLs(info.events[0].description)
  const doc = parser.parseFromString(normalizedDescription, 'text/html')


  return (
    <div style={{width: "30vw"}}>
          <PopUp>
    <div className="header">
        <h1>
          {info.name} | {info.type}
        </h1>
        <h2 className="event-title">{info.events[0].title}</h2>
    </div>
    <p className="date">
      {
      (startDate.getMonth()+1) + "/" + startDate.getDate() + "/" + startDate.getFullYear() + " | " + startTime + " - " +
      (endDate.getMonth()+1) + "/" + endDate.getDate() + "/" + endDate.getFullYear() + " | " + endTime
      }
    </p>
    <p className="address"><strong>Address: </strong>{info.events[0].address}</p>
    <p><strong>Description: </strong><div dangerouslySetInnerHTML={{ __html: doc.body.innerHTML }}/></p>
    <div className="profile-button">
      {info.date}
      <Link key={info.id} to={"/events/" + info.name}>
        <Button isColor="primary" isOutlined>
          View Profile
        </Button>
      </Link>
    </div>
  </PopUp>
    </div>


  );
}