import React, { useState, useMemo } from "react";
import { Container, Row, Col, Jumbotron, Button, Form, Nav, Spinner } from "react-bootstrap";
import { Map } from "../components/map";
import { EventList } from "../components/eventList";
import { getAllEvents } from "../utils/queries";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { OrganizationEventList } from "../components/organizationEventList";
import { AddModal } from "../components/AddModal";

export const Home = () => {
    const [mapViewport, setMapViewport] = useState({
        latitude: 40.015,
        longitude: -105.2705,
        zoom: 14
      });
    const [selectedPanel, setSelectedPanel] = useState("All");
    const [clickedOrganizationId, setClickedOrganizationId] = useState(null);
    const [searchText, setSearchText] = useState("");
    const { isAuthenticated, user } = useAuth0();

    const [addModalShow, setAddModalShow] = useState(false);
    var [today] = useState(() => new Date(Date.now()))

    const { loading, error, data = { organizations: [] } } = useQuery(
        gql(getAllEvents), 
        {
            variables: {time: today},
            fetchPolicy: "cache-and-network",
            pollInterval: 0,
        },
    );

    const clickedOrganizationIndex = useMemo(
        () =>
          data.organizations.findIndex(({ id }) => id === clickedOrganizationId),
        [data.organizations, clickedOrganizationId]
      );

    if (loading)
    return (
        <>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Spinner animation="border" variant="primary" style={{width: "100px", height: "100px"}}/>
            </div>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>Loading...Please Wait!</div>
        </>    
    );
    if (error) {
        return (
            console.log(error)
        )
    }
    
    return (
        <>

            <Jumbotron style={{background: "#E5E5E5"}}>
                <h1>Welcome to Knowhere!</h1>
                Knowhere is a centralized network where users can see upcoming events happening at organizations in their area.
            </Jumbotron>
            <Container className="themed-container" fluid>
                <Row>
                    <Col></Col>
                    <Col xs={2} sm={3} md={3} lg={5}>
                        <h1>Events in Boulder</h1>
                        <Map
                            viewport={mapViewport}
                            onViewportChange={setMapViewport}
                            clickedOrganizationIndex={clickedOrganizationIndex}
                            onOrganizationPinClicked={setClickedOrganizationId}
                            organizations={data.organizations}
                        >
                        </Map>
                        {isAuthenticated && (
                            <div style={{marginTop:"2em"}}>
                                <h1><b>{user.name}'s </b>events</h1>
                                <Button onClick = {() => setAddModalShow(true)}>Add New Event</Button>
                                <OrganizationEventList></OrganizationEventList>
                            </div>
                        )}
                        <div>
                            
                        </div>
                        <div style={{marginTop:"2em"}}>
                            <h2>Upcoming Events</h2>
                            <Nav justify variant="tabs" defaultActiveKey="All">
                                <Nav.Item>
                                    <Nav.Link eventKey="All" onSelect={()=> setSelectedPanel("All")}>All</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Greek" onSelect={()=> setSelectedPanel("Greek")}>Greek</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Music" onSelect={()=> setSelectedPanel("Music")}>Music</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Food_Drink" onSelect={()=> setSelectedPanel("Food & Drink")}>Food & Drink</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Form>
                                <Form.Control type="text" placeholder={"Search For " + selectedPanel + " Organizations"} value={searchText} onChange={(e) => setSearchText(e.target.value)}/>
                            </Form>
                            <EventList data={data} tab={selectedPanel} searchedOrganization={searchText} />
                        </div>

                    </Col>
                    <Col></Col>
                </Row>
                <AddModal
                    show={addModalShow}
                    onHide={() => setAddModalShow(false)}
                />
            </Container>
        </>
    );  
};
