import React from "react";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export const EventList = (props) => {
    const data = props.data

    function replaceURLs(message) {
        if(!message) return;
 
        var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        return message.replace(urlRegex, function (url) {
          var hyperlink = url;
          if (!hyperlink.match('^https?:')) {
            hyperlink = 'http://' + hyperlink;
          }
          var str = '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
          return str;
        });
      }

    //Update so User Can Search
    const filter = data.organizations
        .filter(eventData => {
            return (
                (props.tab ==="All" || eventData.type === props.tab) &&
                eventData.name.toLowerCase().includes(props.searchedOrganization.toLowerCase())
            );
        })

    if(filter.length === 0) {
        return (
            <h6 className="mb-2 text-muted" style={{textAlign: "center", margin: "1em"}}>{props.tab} does not have any upcoming events</h6>
        )
    }

    const events = filter
        .map(organization => {
            var parser = new DOMParser();

            var startDate = new Date(organization.events[0].timestamp);
            var endDate = new Date(organization.events[0].timestamp_end);
            var startTime = startDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true})
            var endTime = endDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true})

            var normalizedDescription = replaceURLs(organization.events[0].description)
            const doc = parser.parseFromString(normalizedDescription, 'text/html')
            return (
                <Card style={{margin: '1em'}}>
                    <Card.Header as="h4">{organization.name}</Card.Header>
                    <Card.Body>
                        <Card.Title>{organization.events[0].title}</Card.Title>
                        <Card.Title>Location: {organization.events[0].address}</Card.Title>
                        <Card.Title>Time: {
                            (startDate.getMonth()+1) + "/" + startDate.getDate() + "/" + startDate.getFullYear() + " @ " + startTime + " - " +
                            (endDate.getMonth()+1) + "/" + endDate.getDate() + "/" + endDate.getFullYear() + " @ " + endTime
                            }
                        </Card.Title>
                        <div dangerouslySetInnerHTML={{ __html: doc.body.innerHTML }}/>
                        <Link
                            key={organization.id}
                            to={"/events/" + organization.name}
                        >
                            <Button variant="primary">View Organization</Button>
                        </Link>
                    </Card.Body>
                </Card>
            );
        })
    
    return(
        <>
            {events}
        </>
    );
}