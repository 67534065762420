import React, { useEffect, useState } from "react";
import {
    Modal,
    Button,
    ModalBody,
    Form,
    Row,
    Col
} from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export const EditModal = (props) => {
    const { getAccessTokenSilently } = useAuth0();

    const [selectedStartDate, handleStartDateChange] = useState("");
    const [selectedEndDate, handleEndDateChange] = useState("");
    const [titleText, setTitleText] = useState("");
    const [descriptionText, setDescriptionText] = useState("");
    const [addressText, setAddressText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);

    const charactersRemaining =  titleText === undefined ? 15 : 15 - titleText.length;


    useEffect( () => {
      setTitleText(props.eventdata.title)
      handleStartDateChange(props.eventdata.timestamp)
      handleEndDateChange(props.eventdata.timestamp_end)
      setDescriptionText(props.eventdata.description)
      setAddressText(props.eventdata.address)
    },[props])

    const deletePost = async () => {
      setIsLoadingDelete(true)
        const token = await getAccessTokenSilently({
            audience: 'https://jfletcher.auth0.com/api/v2/',
        });
        const body = await (await fetch('https://connect-react-app.herokuapp.com/v1/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({query: `
          mutation($id: Int) {
            delete_events(where: {id: {_eq: $id}}) {
              affected_rows
              returning {
                id
              }
            }
          }
          `, variables: {id: props.eventdata.id}}),
          })).json();
          if (body.errors) {
            console.error(body.errors[0].message)
          }
          props.onHide(true)
      }

      const updatePost = async () => {
        setIsLoading(true);
        const token = await getAccessTokenSilently({
            audience: 'https://jfletcher.auth0.com/api/v2/',
        });        
        const body = await (await fetch('https://connect-react-app.herokuapp.com/v1/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({query: `
          mutation ($id: Int, $changes: events_set_input) {
            update_events(where: {id: {_eq: $id}}, _set: $changes) {
              affected_rows
              returning {
                id
                address
                description
                title
                timestamp
                timestamp_end
              }
            }
          }
          `, variables: {id: props.eventdata.id, changes: {address: addressText, description: descriptionText, title: titleText, timestamp: selectedStartDate, timestamp_end: selectedEndDate}}}),
          })).json();
          if (body.errors) {
            console.error(body.errors[0].message)
          }
          props.onHide(true)
      }

      const deleteHandler = () => deletePost().then(() => {
        setIsLoadingDelete(false);
        window.location.reload();
      })
      const updateHandler = () => updatePost().then(() => {
        setIsLoading(false);
        window.location.reload();
      })

    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id = "contained-modal-title-vcenter">
                    {props.eventdata.title}
                </Modal.Title>
            </Modal.Header>
            <ModalBody>
                <Form>
                    <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control aria-describedby="remainingCharacters" type="text" value={titleText} onChange={e => setTitleText(e.target.value)}></Form.Control>
                        <Form.Text id="remainingCharacters" muted>It is recommended to use 15 characters: {charactersRemaining} characters remaining </Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" value={descriptionText} onChange={e => setDescriptionText(e.target.value)}></Form.Control>
                        <Form.Text id="addressHelpBlock" muted>Make sure Links have protocols (http, https) and/or www</Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Address</Form.Label>
                        <Form.Control aria-describedby="addressHelpBlock" type="text" value={addressText} onChange={e => setAddressText(e.target.value)}></Form.Control>
                        <Form.Text id="addressHelpBlock" muted>Input an accurate address for best results</Form.Text>
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group>
                                <div>
                                <Form.Label>Beginning Date/Time</Form.Label>
                                </div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DateTimePicker inputVariant="outlined" value={selectedStartDate} onChange={handleStartDateChange} />
                                </MuiPickersUtilsProvider>  
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <div>
                                    <Form.Label>End Date/Time</Form.Label>
                                </div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DateTimePicker inputVariant="outlined" value={selectedEndDate} onChange={handleEndDateChange} />
                                </MuiPickersUtilsProvider>  
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <Modal.Footer>
                <Button variant="danger" type="submit" onClick={!isLoadingDelete ? deleteHandler : null} style={{position:"absolute", left:"0"}}> {isLoadingDelete ? 'Loading...' : 'Delete Event'} </Button>
                <Button variant="secondary" onClick={() => props.onHide(true)}>Close</Button>
                <Button variant="primary" onClick={!isLoading ? updateHandler : null}>{isLoading ? 'Loading...' : 'Save Changes'}</Button>
            </Modal.Footer>
        </Modal>
    );
}