import React, {useState} from "react";
import {Navbar, Nav, Button, Dropdown, DropdownButton} from "react-bootstrap";
import { SignInModal } from "../components/SignInModal";
import { useAuth0 } from "@auth0/auth0-react";

export const NavigationBar = () => {
    const [modalSignInShow, setModalSignInShow] = useState(false);
    const { user, isAuthenticated, logout } = useAuth0();

    return (
        <>
            <Navbar style={{background: "#338FDC"}} variant="dark">
                <Navbar.Brand href="/">
                    <img 
                        src="https://cdn.glitch.com/05daeb1c-a10e-4a20-95cb-07da926940b3%2FKW_GreyWhite-8.png?v=1629658828988"
                        width="80"
                        height="50"
                        alt="knowhere"
                    />
                </Navbar.Brand>
                <Nav className="mr-auto">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/organizations">Organizations</Nav.Link>
                </Nav>

                {!isAuthenticated && (
                    <Button 
                        variant="outline-light"
                        size = "sm"
                        onClick = {() => setModalSignInShow(true)}
                    >
                    Sign in / Create Account
                    </Button>
                )}

                {isAuthenticated && (
                    <DropdownButton
                        variant = "outline-light"
                        size = "sm"
                        title = { "Welcome Back, "+user.name+"!"}
                    >
                        <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                        <Dropdown.Item href="/profile/settings">Settings</Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item onClick={() => logout({ returnTo: window.location.origin})}>Logout</Dropdown.Item>
                    </DropdownButton>
                )}

                <SignInModal
                    show={modalSignInShow}
                    onHide={() => setModalSignInShow(false)}
                />
            </Navbar>        
        </>
    );        
};

