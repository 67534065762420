import './App.css';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import { Home }  from "./pages/home";
import { Organization } from "./pages/organization_events";
import { Profile } from "./pages/profile";
import { ProfileSettings } from "./pages/profile_settings";
import { AllOgranizations } from "./pages/organizations";
import { Enrollment } from './pages/enrollment';
import { ApolloClient, HttpLink, InMemoryCache } from "apollo-boost";
import { ApolloProvider } from "react-apollo";

const cache = new InMemoryCache();
const link = new HttpLink({
  uri: "https://connect-react-app.herokuapp.com/v1/graphql",
  headers: {
    "x-hasura-role" : "anonymous" 
  }
});

const client = new ApolloClient({
  cache,
  link,
});

function App() {
  return (
    <ApolloProvider client= {client}>
      <Router>
        <Switch>
          <Route path="/events/:organizationName" component={Organization} />
          <Route path="/organizations">
            <AllOgranizations/>
          </Route>
          <Route path="/profile/settings">
            <ProfileSettings/>
          </Route>
          <Route path="/profile">
            <Profile/>
          </Route>
          <Route path="/Signup">
            <Enrollment/>
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>

      </Router>
    </ApolloProvider>

  );
}

export default App;
