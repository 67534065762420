import React from "react";
import {
    Alert
} from "react-bootstrap";

export const AlertEvent = (props) => {
    var title = " "
    var variantType = " "

    if(props.isError === true) {
        title = "Error!"
        variantType = "danger"
    } else {
        title = "Success!"
        variantType = "success"
    }

    return(
        <Alert {...props}  variant={variantType} dismissible style={{position: "fixed", right:"1em", bottom:"0", zIndex:"9"}}>
            <Alert.Heading>{title}</Alert.Heading>
            <hr/>
            <div>
                {props.message}
            </div>
        </Alert>
    )
}