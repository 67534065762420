import React, { useEffect, useState } from "react";
import { Card, Button, Spinner, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { EditModal } from "./EditModal";

export const OrganizationEventList = () => {
    const { isAuthenticated, getAccessTokenSilently , isLoading} = useAuth0();
    const [events, setEvents] = useState([]);
    const [editModalShow, setEditModalShow] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState({});
    const [eventsLoading, setEventsLoading] = useState(false);

    useEffect(() => {
        const loadEvents = async () => {
            setEventsLoading(true);
            const token = await getAccessTokenSilently({
                audience: 'https://jfletcher.auth0.com/api/v2/',
            });
          const body = await (await fetch('https://connect-react-app.herokuapp.com/v1/graphql', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({query: `query { events { id, title, description, timestamp, address, timestamp_end}}`}),
          })).json();
          if (body.errors) {
            console.error(body.errors[0].message)
          } else {
            setEvents(body.data.events)
          }
        }
    
        isAuthenticated && loadEvents().then(() => {
            setEventsLoading(false);
        })
       }, [isAuthenticated, getAccessTokenSilently])

    if (isLoading || eventsLoading) {
        return(     
            <>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Spinner animation="border" variant="primary" style={{width: "100px", height: "100px"}}/>
                </div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>Loading Events....</div>
            </>    
        )
    }

    function replaceURLs(message) {
        if(!message) return;
 
        var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        return message.replace(urlRegex, function (url) {
          var hyperlink = url;
          if (!hyperlink.match('^https?:')) {
            hyperlink = 'http://' + hyperlink;
          }
          return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
        });
      }

    const event_specific = events.map(eventData => {
        var startDate = new Date(eventData.timestamp);
        var endDate = new Date(eventData.timestamp_end)
        var today = new Date();
        var expiredEvent = eventData.timestamp_end === null ? startDate : endDate < today;
        var parser = new DOMParser();
        var normalizedDescription = replaceURLs(eventData.description)
        const doc = parser.parseFromString(normalizedDescription, 'text/html')
        var startTime = startDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true})
        var endTime = endDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true})

        return (
            <>
                <Card style={{marginTop: '1em'}}>
                    <Card.Header as="h4">{eventData.title}
                        <div  style={{float: "right"}}>
                            {expiredEvent && (
                                <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip>This event is expired and will not show on Knowhere!</Tooltip>}
                                >
                                    <Badge pill variant="danger">Expired</Badge>
                                </OverlayTrigger>
                            )}{' '}
                            <Button variant="outline-primary" onClick = {() => {
                                setSelectedEvent(eventData)
                                setEditModalShow(true)
                                }}>Edit</Button>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Card.Title>Location: {eventData.address}</Card.Title>
                        <Card.Title>Time: {
                            (startDate.getMonth()+1) + "/" + startDate.getDate() + "/" + startDate.getFullYear() + " @ " + startTime +" - " +
                            (endDate.getMonth()+1) + "/" + endDate.getDate() + "/" + endDate.getFullYear() + " @ " + endTime
                        }
                        </Card.Title>

                        <div dangerouslySetInnerHTML={{ __html: doc.body.innerHTML }}/>
                    </Card.Body>
                </Card>

                <EditModal
                    show={editModalShow}
                    onHide={() => setEditModalShow(false)}
                    eventdata = {selectedEvent}
                />
            </>
        )
    });

    return(
        <>
            {event_specific}
        </>
    );
}