import firebase from "firebase/app";
import "firebase/storage";
import "firebase/analytics";

const app = firebase.initializeApp({
  apiKey: "AIzaSyDnlAABIaMQJJSp-sMU72RSVwjzdkAN4Mc",
  authDomain: "knowhere-848a5.firebaseapp.com",
  projectId: "knowhere-848a5",
  storageBucket: "knowhere-848a5.appspot.com",
  messagingSenderId: "524239422754",
  appId: "1:524239422754:web:ab391bed72cbc97647517b",
  measurementId: "G-X09J93LJCE"
});

// Get a reference to the storage service, export it for use
export const storage = firebase.storage(); 
export const defaultAnalytics = firebase.analytics();

async function getDefault() {
  try {
    return await storage.ref('/profilePictures/default.png').getDownloadURL()
  } catch(e) {
    console.log(e)
  }
}

export const defaultImage = getDefault();
export default app;
