export const getAllEvents =`
query getAllEvents($time: timestamptz!) {
  organizations(where: {events: {id: {_neq: null}, _and: {timestamp_end: {_gte: $time}}}}) {
    name
    profile_picture
    type
    id
    events(limit: 1, order_by: {timestamp: asc}) {
      description
      title
      timestamp
      timestamp_end
      address
      latitude
      longitude
    }
  }
}
`;

export const organizationEvents = `
query organizationEvents($organizationName: String!, $time: timestamptz!) {
  organizations(where: {name: {_eq: $organizationName}}) {
    type
    bio
    profile_picture
    events(order_by: {timestamp: asc}, where: {timestamp_end: {_gte: $time}}) {
      description
      title
      timestamp
      timestamp_end
      address
    }
  }
}

`;

export const getAllOrganizations = `
{
  organizations(order_by: {type: asc}, where: {type: {_neq: "null"}}) {
    type
    name
    bio
    profile_picture
  }
}
`;

export const submitOrganizationApplication = `
  mutation ($description: String!, $email: String!, $name: String!, $phone: String!, $website: String!, $type: String!) {
    insert_applications(objects: {description: $description, email: $email, name: $name, type: $type, phone: $phone, website: $website}) {
      affected_rows
    }
  }
`