import React, { useState } from "react";
import { Card, Button, Spinner } from "react-bootstrap";
import { getAllOrganizations } from "../utils/queries";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { Link } from "react-router-dom";
import { defaultImage } from "../utils/firebase_config";

export const AllOgranizations = () => {
    const { loading, data = { organizations: [] } } = useQuery(
        gql(getAllOrganizations));
    const [ profilePictureLink, setProfilePictureLink] = useState("")

    if (loading)
        return (
            <div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Spinner animation="border" variant="primary" style={{width: "100px", height: "100px"}}/>
                </div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>Loading...Please Wait!</div>
            </div>    
        );

    const organizations = data.organizations
    .map(organization => {
        if(organization.profile_picture === null) {
            defaultImage.then((url) => {
                setProfilePictureLink(url);
            })
        } 
            
        return(
            <Card className="text-center">
                <Card.Img variant="top" src= {organization.profile_picture === null ? profilePictureLink : organization.profile_picture}/>
                <Card.Body>
                    <Card.Title>{organization.name}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{organization.type}</Card.Subtitle>
                    <Card.Text>{organization.bio}</Card.Text>
                        <Link
                        key={organization.id}
                        to={"/events/" + organization.name}
                        >
                        <Button variant="primary" style={{position:"relative", bottom:"0"}}>View Organization</Button>
                    </Link>                
                </Card.Body>
            </Card>
        )
    })    
    return(
        <div style={{marginTop:"1em"}}>
            <h1 className="text-center">Organizations on the Knowhere Network</h1>
            <div style={{display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))", gap:"1em", margin:"3em"}}>
                {organizations}
            </div>
      </div>    
    );
}