import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Spinner, Form, Image } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { storage } from "../utils/firebase_config";
import {v4 as uuidv4} from 'uuid';
import { AlertEvent } from "../components/AlertEvent";

export const ProfileSettings = () => {
    const { isAuthenticated, getAccessTokenSilently , isLoading, user} = useAuth0();
    const [profilePictureLink, setProfilePictureLink] = useState("");
    const [image, setImage] = useState(null)
    const [bio, setBio] = useState("");
    const [alert, setAlert] = useState({
        alertShow: false, alertMessage: "Something happened", iserror: false
    })
    //const [alertShow, setAlertShow] = useState(false);
   // const [alertMessage, setAlertMessage] = useState("");

    useEffect(() => {
        const loadSettings = async () => {
            const token = await getAccessTokenSilently({
                audience: 'https://jfletcher.auth0.com/api/v2/',
            });
          const body = await (await fetch('https://connect-react-app.herokuapp.com/v1/graphql', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({query: `query { organizations { bio, profile_picture, id }}`}),
          })).json();
          if (body.errors) {
            setAlert({...alert,
                alertMessage: "We had a Problem Fetching Your Settings! Please Try Again Later!", 
                iserror: true,
                alertShow: true
            })
          } else {
            setProfilePictureLink(body.data.organizations[0].profile_picture)
            setBio(body.data.organizations[0].bio)
          }
        }
    
        isAuthenticated && loadSettings()
        // eslint-disable-next-line react-hooks/exhaustive-deps
       }, [isAuthenticated, getAccessTokenSilently])

    const updateSettings = async ({id, bio, url}) => {
    const token = await getAccessTokenSilently({
        audience: 'https://jfletcher.auth0.com/api/v2/',
    });        
    const body = await (await fetch('https://connect-react-app.herokuapp.com/v1/graphql', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({query: `
        mutation ($id: String!, $changes: organizations_set_input) {
            update_organizations(_set: $changes, where: {auth0_id: {_eq: $id}}) {
              affected_rows
              returning {
                bio
                profile_picture
              }
            }
          }
          
        `, variables: {id, changes: {bio, profile_picture: url}}}),
        })).json();
        if (body.errors) {
            setAlert({...alert,
                alertMessage: "We had a Problem Updating Your Settings! Please Try Again Later!", 
                iserror: true,
                alertShow: true
            })
        }
    }
    
    const submitChanges = () => {
        if (image) {
            const storageRef = storage.ref();
            const imageRef = storageRef.child("/profilePictures/" + user.name + "-" +uuidv4())
            imageRef.put(image).then(() => {
                fetchProfileUrl(imageRef).then((url) => {
                    const updatedSettings = {
                        url, 
                        bio,
                        id: user.sub,
                    }
                    updateSettings(updatedSettings).then(() => {
                        setAlert({...alert,
                            alertMessage: "Your Profile was Succesfully Updated!", 
                            iserror: false,
                            alertShow: true
                        })
                    })
                    setProfilePictureLink(url)
                })
            })

        } else {
            console.log("No File Uploaded")
        }
    }

    if (isLoading) {
        return (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Spinner animation="border" variant="primary" style={{width: "100px", height: "100px"}}/>
            </div>
        );
    }

    const onImageChange = (e) => {

        let allowedExtension = ['image/jpeg', 'image/jpg', 'image/png'];
        const reader = new FileReader();

        let file = e.target.files[0]
        if (file) {
            if (allowedExtension.indexOf(file.type) <= -1) {
                setAlert({...alert,
                    alertMessage: "Invalid Image Type! Please Upload a JPG or PNG.", 
                    iserror: true,
                    alertShow: true
                })
                return;
            } else {
                setAlert({...alert,
                    alertShow: false,
                    alertMessage: "", 
                    iserror: false,
                })
            }
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setImage(file)
                }
            };
            reader.readAsDataURL(e.target.files[0])
        } else {
            setImage(null);
        }
    }

    const fetchProfileUrl = async (imageRef) => {
        const response = await imageRef.getDownloadURL();
        const url = await response;
        return url;
    }

    return (
        <Container className="themed-container" fluid>
            <AlertEvent
                show = {alert.alertShow}
                onClose = {() => setAlert({...alert, alertShow: false})}
                isError = {alert.iserror}
                message = {alert.alertMessage}
            />
            <Row>
                <Col></Col>
                <Col>
                <div style={{marginTop: "1em", border: ".2rem solid #ececec", borderRadius: "8px 8px"}}>
                    <div style={{textAlign: "center", marginTop: "1em"}}>
                        <h2>Settings</h2>
                        <Image src={profilePictureLink} rounded/>
                    </div>
                    <div style={{margin:"1em"}}>
                        <Form.Group>
                            <Form.Label>Bio</Form.Label>
                            <Form.Control type="text" value={bio} onChange={e => {
                                setBio(e.target.value)
                                }}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Profile Picture</Form.Label>
                            <Form.File type="file" accept='image/jpeg, image/jpg, image/png' onChange={e => onImageChange(e)} />
                            <Form.Text className="text-muted">JPG and PNG only</Form.Text>
                        </Form.Group>
                    </div>
                    <Button  style={{margin:"1em"}} disabled={alert.iserror} onClick={() => submitChanges()}>Save</Button>

                </div>

                </Col>
                <Col></Col>
            </Row>
        </Container>    
    );
}