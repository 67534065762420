import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Spinner, Image } from "react-bootstrap";
import { OrganizationEventList } from "../components/organizationEventList";
import { AddModal } from "../components/AddModal";
import { useAuth0 } from "@auth0/auth0-react";


export const Profile = () => {
    const [addModalShow, setAddModalShow] = useState(false);
    const { isAuthenticated, getAccessTokenSilently , isLoading, user} = useAuth0();
    const [profile, setProfile] = useState({});

    useEffect(() => {
        const loadProfile = async () => {
            const token = await getAccessTokenSilently({
                audience: 'https://jfletcher.auth0.com/api/v2/',
            });
          const body = await (await fetch('https://connect-react-app.herokuapp.com/v1/graphql', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({query: `query { organizations { bio profile_picture }}`}),
          })).json();
          if (body.errors) {
            console.error(body.errors[0].message)
          } else {
            setProfile(body.data.organizations[0])
          }
        }
    
        isAuthenticated && loadProfile()
       }, [isAuthenticated, getAccessTokenSilently])

    if(isLoading) {
        return (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Spinner animation="border" variant="primary" style={{width: "100px", height: "100px"}}/>
            </div>
        );
    }

    return(
        <Container className="themed-container" fluid>
            <Row>
                <Col></Col>
                <Col style={{marginTop: "1em", border: ".2rem solid #ececec", borderRadius: "8px 8px"}}>
                    <div style={{marginTop: "1em", marginBottom: "1em"}}>
                        <div className="text-center">
                            <Image src={profile.profile_picture} rounded/>
                            <h1 >{user.name}'s Profile</h1>
                            <h3 className="mb-2 text-muted">{profile.bio}</h3>
                        </div>
                        <Button onClick = {() => setAddModalShow(true)}>Add New Event</Button>
                        <OrganizationEventList/>
                    </div>
                    
                </Col>
                <Col></Col>
            </Row>
            <AddModal
                show={addModalShow}
                onHide={() => setAddModalShow(false)}
            />
        </Container>

    )
}