import React, { useState } from "react";
import {
    Modal,
    Button,
    ModalBody,
    Form,
    Row,
    Col
} from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export const AddModal = (props) => {
    const { getAccessTokenSilently } = useAuth0();

    const [selectedStartDate, handleStartDateChange] = useState(new Date());
    const [selectedEndDate, handleEndDateChange] = useState(new Date());
    const [titleText, setTitleText] = useState("");
    const [descriptionText, setDescriptionText] = useState("");
    const [addressText, setAddressText] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const charactersRemaining =  titleText === undefined ? 15 : 15 - titleText.length;


    const submitPost = async () => {
        setIsLoading(true)
        const postBody = {
          address: addressText,
          description: descriptionText,
          title: titleText,
          timestamp: selectedStartDate,
          timestamp_end: selectedEndDate,
          latitude: 40.0084,
          longitude: -105.27213
        }
        const token = await getAccessTokenSilently({
            audience: 'https://jfletcher.auth0.com/api/v2/',
        });
        const body = await (await fetch('https://connect-react-app.herokuapp.com/v1/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({query: `
          mutation($address: String, $description: String, $title: String, $timestamp: timestamptz, $timestamp_end: timestamptz, $latitude: Float, $longitude: Float ) {
            insert_events(objects: [{address: $address, description: $description, title: $title, timestamp: $timestamp, timestamp_end: $timestamp_end, latitude: $latitude, longitude: $longitude}]) {
              affected_rows
              returning {
                address
                description
                title
                timestamp
                latitude
                longitude
              }
            }
          }
          `, variables: {...postBody}}),
          })).json();
          if (body.errors) {
            console.error(body.errors[0].message)
          }
          props.onHide(true)
      }

      const submitHandler = () => submitPost().then(() => {
          setIsLoading(false)
          window.location.reload();
      })

    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id = "contained-modal-title-vcenter">
                    New Event
                </Modal.Title>
            </Modal.Header>
            <ModalBody>
                <Form>
                    <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control aria-describedby="remainingCharacters" type="text" value={titleText} placeholder="Enter Title" onChange={e => setTitleText(e.target.value)}></Form.Control>
                        <Form.Text id="remainingCharacters" muted>It is recommended to use 15 characters: {charactersRemaining} characters remaining </Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" value={descriptionText} placeholder="Enter Description" onChange={e => setDescriptionText(e.target.value)}></Form.Control>
                        <Form.Text id="addressHelpBlock" muted>Make sure Links have protocols (http, https) and/or www</Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Address</Form.Label>
                        <Form.Control aria-describedby="addressHelpBlock" type="text" value={addressText} placeholder="Enter Address" onChange={e => setAddressText(e.target.value)}></Form.Control>
                        <Form.Text id="addressHelpBlock" muted>Input an accurate address for best results</Form.Text>
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group>
                                <div>
                                <Form.Label>Beginning Date/Time</Form.Label>
                                </div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DateTimePicker inputVariant="outlined" value={selectedStartDate} onChange={handleStartDateChange} />
                                </MuiPickersUtilsProvider>  
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <div>
                                    <Form.Label>End Date/Time</Form.Label>
                                </div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DateTimePicker inputVariant="outlined" value={selectedEndDate} onChange={handleEndDateChange} />
                                </MuiPickersUtilsProvider>  
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.onHide(true)}> Close </Button>
                <Button variant="primary" type="submit" onClick={!isLoading ? submitHandler : null}>{isLoading ? 'Loading...' : 'Add Event'}</Button>
            </Modal.Footer>
        </Modal>
    );
}