import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import {
    Modal,
    Button,
    Card,
    CardGroup,
    Container,
    Row,
    Col
} from "react-bootstrap";

export const SignInModal = (props) => {

    const { loginWithRedirect } = useAuth0();

    return(
        <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
            <Modal.Header closeButton>
                <Modal.Title id = "contained-modal-title-vcenter">
                    Welcome!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CardGroup>
                    <Card>
                        <Card.Img variant="top" src="https://via.placeholder.com/500.png?text=Organizations"/>
                        <Card.Body>
                            <Card.Title>Organization</Card.Title>
                            <Card.Text>
                                Get started setting up your organization on the Knowhere network or log back in to access your events.
                            </Card.Text>
                            <Container>
                                <Row>
                                    <Col>
                                        <Button size="lg" class="text-center" block onClick={() => loginWithRedirect()}>Log In</Button>
                                    </Col>
                                    <Col>
                                        <Button size ="lg" class="text-center" href="Signup" block>Join</Button>
                                    </Col>
                                </Row>
                            </Container>

                        </Card.Body>
                    </Card>
                </CardGroup>
            </Modal.Body>
        </Modal>
    );
}

